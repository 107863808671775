<template>
  <v-container fluid class="mt-10">
    <!-- Waste Incinerators Section -->
    <v-row class="mt-5">
      <v-col cols="12">
        <v-card class="text-center">
          <v-card-title>
            <h1 class="headline">MicoTech Engineering</h1>
          </v-card-title>
          <v-card-subtitle class="subtitle">Innovative Waste Management Technology</v-card-subtitle>
            </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="section-header">
          <h1 class="section-title">MicoTech Waste Incinerators</h1>
          <p class="section-description">
            High-performance solutions for safe and eco-friendly waste disposal.
          </p>
        </div>

        <v-carousel
          hide-delimiter-background
          show-arrows
          cycle
          :interval="7000"
          height="500px"
          class="stylish-carousel"
        >
          <v-carousel-item v-for="(incinerator, index) in incinerators" :key="index" class="carousel-item">
            <!-- Background Image -->
            <v-img
              :src="incinerator.image"
              class="carousel-bg"
              max-height="650px"
              min-height="200px"
              width="100%"
              cover
            ></v-img>

            <!-- Overlay Text Content -->
            <div class="carousel-text-overlay">
              <v-col class="text-center d-flex flex-column align-center justify-center">
                <!-- Title with fade-in animation -->
                <transition name="fade-slide" mode="out-in">
                  <h1
                    v-if="incinerator.model"
                    class="display-2 white--text font-weight-bold animated fadeInDown text-shadow"
                    :key="`title-${index}`"
                  >
                    {{ incinerator.model }}
                  </h1>
                </transition>

                <!-- Subtitle with fade-in effect after title -->
                <transition name="fade-slide" mode="out-in">
                  <h3
                    v-if="incinerator.description"
                    class="white--text mb-4 text-shadow animated fadeIn delay-1s"
                    :key="`subtitle-${index}`"
                  >
                    {{ incinerator.description }}
                  </h3>
                </transition>

                <!-- Button with hover effect and animation -->
                <v-btn
                  color="success"
                  class="elevated-btn animated fadeInUp delay-2s stylish-button"
                  @click="openDialog(incinerator)"
                >
                  Learn More
                </v-btn>
              </v-col>
            </div>
          </v-carousel-item>
        </v-carousel>

        <!-- Why Choose MicoTech Section -->
        <v-card class="why-choose-card my-5 elevation-10">
          <v-card-title>
            <h2 class="headline text-center">Why Choose MicoTech Waste Incinerators?</h2>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(benefit, index) in benefits" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="benefit-title">{{ benefit.title }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ benefit.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div class="contact-info text-center mt-4">
              <p>
                Contact <strong>MicoTech Engineering PLC</strong> today to discover how our waste incinerators can revolutionize your waste management processes.
              </p>
            </div>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-title class="title">
            <span class="material-icons"></span> Key Features of Waste Incinerators
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(feature, index) in incineratorFeatures" :key="index">
                <v-list-item-content>
                  <v-list-item-title class="benefit-title">{{ feature.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ feature.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Baking Ovens Section -->
    <!-- Ovens Section -->
    <v-row>
  <v-col cols="6">
    <h2 class="section-title">Baking Ovens</h2>
    
    <!-- Carousel for Baking Ovens -->
    <v-carousel>
      <v-carousel-item
        v-for="(oven, index) in ovens"
        :key="index"
        class="stylish-carousel"
      >
        <v-img :src="oven.image" class="carousel-bg" height="400px"></v-img>
        <div class="carousel-text-overlay">
          <h3>{{ oven.model }}</h3>
          <p>{{ oven.description }}</p>
          <v-btn :href="oven.action" class="stylish-button" color="primary">View Details</v-btn>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-col>

  <v-col cols="6">
  <!-- List of Oven Descriptions -->
  <v-card elevation="10" class="oven-card" style="background-color: #f9f9f9; border-radius: 10px;">
    <v-list>
      <v-list-item-group>
        <v-list-item v-for="(oven, index) in ovens" :key="index" class="oven-list-item">
          
          <v-list-item-content>
            <v-list-item-title class="benefit-title" style="font-size: 1.5rem; font-weight: bold; color: #4A4A4A;">
              {{ oven.model }}
            </v-list-item-title>
            <v-list-item-subtitle class="oven-description" style="font-size: 1.1rem; color: #7A7A7A;">
              {{ oven.description }}
            </v-list-item-subtitle>

            <!-- Features of the Oven -->
            <v-list-item-group class="features-list">
              <v-list-item v-for="(feature, fIndex) in oven.features" :key="fIndex">
                <v-list-item-content>
                  <v-list-item-title style="color: #3f51b5; font-weight: bold;">
                    • {{ feature }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-title style="margin-top: 10px; font-weight: bold;">Specifications:</v-list-item-title>
            
            <!-- Specifications of the Oven -->
            <v-list-item-group class="specifications-list">
              <v-list-item v-for="(spec, sIndex) in oven.specifications" :key="sIndex">
                <v-list-item-content>
                  <v-list-item-title style="color: #4A4A4A;">
                    {{ spec.label }}: <span style="font-weight: normal; color: #7A7A7A;">{{ spec.value }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</v-col>
</v-row>


      <!-- Why Choose MicoTech Ovens Section -->
      <v-row>
      <v-card elevation="10">
        <v-col cols="6">
          <h2 class="headline text-center">Why Choose MicoTech Ovens?</h2>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(benefit, index) in benefitsOvens" :key="index">
              <v-list-item-content>
                <v-list-item-title class="benefit-title">{{ index + 1 }}. {{ benefit.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ benefit.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <p>
          Choose MicoTech ovens for a perfect blend of efficiency, versatility, and reliability in your baking operations. Contact us today to find the ideal oven solution for your business needs.
        </p>
      </v-col>
      </v-card>
    </v-row>

    <!-- Dialog for Detailed Information -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card class="stylish-dialog">
        <v-card-title class="headline">
          <span>{{ selectedItem?.model }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-img :src="selectedItem?.image" aspect-ratio="1.5"></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <p><strong>Description:</strong> {{ selectedItem?.description }}</p>
                <p><strong>Features:</strong></p>
                <ul>
                  <li v-for="(feature, index) in selectedItem?.features" :key="index">
                    {{ feature }}
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedItem: null,
      incineratorFeatures: [
        {
          title: "High-Temperature Operation",
          description: "Operates at temperatures up to 1250°C, reducing waste volume and weight by over 95%."
        },
        {
          title: "Advanced Air Pollution Control",
          description: "Filters toxic gases and ensures minimal environmental impact."
        },
        {
          title: "Durable Construction",
          description: "Built with high-grade materials for long-term reliability."
        },
        {
          title: "Fuel Flexibility",
          description: "Compatible with diesel, LPG, or natural gas."
        },
        {
          title: "Scalable Capacity",
          description: "Models range from 200kg to higher load capacities."
        }
      ],
      incinerators: [
        {
          model: 'MICO 00204',
          description: 'Advanced incinerator with 200kg capacity per load.',
          features: ['High efficiency', 'Low emissions', 'User-friendly controls'],
          image: require('@/assets/m1.jpg'),
          action: '#', // Add action URL here
        },
        {
          model: 'MICO 00207',
          description: 'Designed for larger waste volumes with safe emissions.',
          features: ['Durable construction', 'Automatic operation', 'Energy-saving'],
          image: require('@/assets/m/m13.jpg'),
          action: '#', // Add action URL here
        },
      ],
      ovens: [
        {
          model: 'MICO 00204/2008',
          description: 'Bakes 432 breads in just 10-12 minutes efficiently.',
          features: ['Energy-efficient', 'Even heat distribution', 'Compact design'],
          specifications: [
            { label: 'Power Consumption', value: '2kW at 220V' },
            { label: 'Baking Capacity', value: '432 breads in 10-12 minutes' },
            { label: 'Baking Area', value: '4.2 square meters (1.4m x 3m)' },
            { label: 'Energy Sources', value: 'Wood, fuel, biogas, and oil' },
            { label: 'Batteries', value: '12' },
            { label: 'Fuel Consumption', value: '1.7 liters per hour' },
            { label: 'Dimensions', value: '200mm (H) x 180mm (D) x 260mm (W)' },
          ],
          image: require('@/assets/m/m10.jpg'),
          action: '#', // Add action URL here
        },
        {
          model: 'MICO 00205/2009',
          description: 'Baking capacity for 720 breads in 10-13 minutes.',
          features: ['Fast baking times', 'User-friendly interface', 'Easy cleaning'],
          specifications: [
            { label: 'Power Consumption', value: '2.2kW at 220V or 380V' },
            { label: 'Baking Capacity', value: '720 breads in 10-13 minutes' },
            { label: 'Baking Area', value: '6.48 square meters (2.16m x 3m)' },
            { label: 'Energy Sources', value: 'Wood, fuel, biogas, and oil' },
            { label: 'Batteries', value: '18' },
            { label: 'Fuel Consumption', value: '2 liters per hour' },
            { label: 'Dimensions', value: '220mm (H) x 180mm (D) x 300mm (W)' },
          ],
          image: require('@/assets/m/m11.jpg'),
          action: '#', // Add action URL here
        },
        {
          model: 'MICO 00206/2010',
          description: 'Baking capacity for 960 breads in 10-13 minutes.',
          features: ['Fast baking times', 'User-friendly interface', 'Easy cleaning'],
          specifications: [
            { label: 'Power Consumption', value: '3kW at 220V or 380V' },
            { label: 'Baking Capacity', value: '960 breads in 10-13 minutes' },
            { label: 'Baking Area', value: '9.2 square meters (2.3m x 4m)' },
            { label: 'Energy Sources', value: 'Wood, fuel, biogas, and oil' },
            { label: 'Batteries', value: '24' },
            { label: 'Fuel Consumption', value: '3 liters per hour' },
            { label: 'Dimensions', value: '220mm (H) x 180mm (D) x 300mm (W)' },
          ],
          image: require('@/assets/m/m12.jpg'),
          action: '#', // Add action URL here
        },
        {
          model: 'MICO 00207/2013',
          description: 'Baking capacity for 864 breads in 10-13 minutes.',
          features: ['High performance', 'Durable construction', 'Low maintenance'],
          specifications: [
            { label: 'Power Consumption', value: '3kW at 220V or 380V' },
            { label: 'Baking Capacity', value: '864 breads in 10-13 minutes' },
            { label: 'Baking Area', value: '8.64 square meters (2.16m x 4m)' },
            { label: 'Energy Sources', value: 'Wood, fuel, biogas, and oil' },
            { label: 'Batteries', value: '24' },
            { label: 'Fuel Consumption', value: '2.5 liters per hour' },
            { label: 'Dimensions', value: '220mm (H) x 180mm (D) x 300mm (W)' },
          ],
          image: require('@/assets/m/m13.jpg'),
          action: '#', // Add action URL here
        },
      ],
      benefits: [
        {
          title: 'Reliability',
          description: 'Engineered for consistent, high-performance operation',
        },
        {
          title: 'Cost-Effectiveness',
          description: 'Efficient design reduces operational costs',
        },
        {
          title: 'Environmental Responsibility',
          description: 'Minimizes environmental impact',
        },
        {
          title: 'Compliance',
          description: 'Meets or exceeds industry standards and regulations',
        },
        {
          title: 'Versatility',
          description: 'Suitable for various healthcare and industrial applications',
        },
      ],
      benefitsOvens: [
        {
          title: "Versatile Energy Options",
          description: "Multiple energy sources: wood, fuel, biogas, and oil. Adaptable to various operational environments and energy availabilities. Reduces dependency on a single energy source.",
        },
        {
          title: "High Baking Capacity",
          description: "Models ranging from 432 to 960 bread per cycle. Efficient for both small and large-scale operations. Increases productivity and output.",
        },
        {
          title: "Energy Efficiency",
          description: "Low power consumption (2kW to 3kW) relative to capacity. Helps reduce operational costs. Environmentally friendly operation.",
        },
        {
          title: "Flexible Power Supply",
          description: "Options for 220V or 380V power supply. Easily integrates into existing electrical systems. Suitable for various global power standards.",
        },
        {
          title: "Compact Design",
          description: "Space-efficient models to suit different facility sizes. Easy to install and integrate into existing setups. Maximizes floor space utilization.",
        },
        {
          title: "Rapid Baking Times",
          description: "Quick baking cycles of 10-13 minutes. Increases overall production efficiency. Allows for faster turnover and fresher products.",
        },
        {
          title: "Durable Construction",
          description: "Built to withstand intensive use. Long-lasting performance for better return on investment. Reduces need for frequent replacements or repairs.",
        },
        {
          title: "Customizable Options",
          description: "Multiple models to choose from based on specific needs. Scalable solutions for growing businesses. Tailored to meet individual production requirements.",
        },
        {
          title: "Innovative Technology",
          description: "Incorporates latest advancements in baking technology. Ensures consistent baking results. Improves overall product quality.",
        },
        {
          title: "Excellent Customer Support",
          description: "Dedicated team for installation and maintenance. Ongoing technical support. Ensures smooth operation and minimizes downtime.",
        },
      ],
    };
  },
  methods: {
    openDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
/* General Styles */
.section-header {
  text-align: center;
  margin-bottom: 30px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.section-description {
  font-size: 1.2rem;
  color: #555;
}

.stylish-carousel .v-carousel-item {
  position: relative;
}

.carousel-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.carousel-bg {
  border-radius: 10px;
}

.stylish-dialog {
  border-radius: 10px;
}

.elevated-btn {
  border-radius: 25px;
}

.stylish-button {
  transition: background-color 0.3s;
}

.stylish-button:hover {
  background-color: #f1f1f1;
}

/* Animation Styles */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.benefit-title {
  font-weight: bold;
  color: #007BFF;
}
.oven-card {
  transition: transform 0.2s;
}

.oven-card:hover {
  transform: translateY(-5px);
}
.headline {
  font-weight: bold;
  font-size: 2.5rem;
  color: #3f51b5;
}
.oven-list-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.features-list {
  margin-top: 10px;
  margin-bottom: 10px;
}

.specifications-list {
  margin-top: 5px;
}
</style>
