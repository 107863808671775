<template>
  <v-container class="pa-5" fluid>
    <!-- About Card -->
    <v-card class="mt-5 elevation-5 rounded-xl stylish-card">
      <v-card-title class="d-flex justify-space-between align-center stylish-header">
        <div class="flex-grow-1 text-center">
          <h2 class="text-h4 font-weight-bold text-primary stylish-title">About Michotech Engineering</h2>
        </div>
        <v-btn @click="goBack" icon>
          <v-icon class="icon-primary">mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="stylish-card-text">
        <v-row>
          <!-- Carousel Section -->
          <v-col cols="12" md="6">
            <v-carousel hide-delimiters show-arrows interval="4000" transition="fade" cycle class="stylish-carousel">
              <v-carousel-item v-for="(image, index) in aboutImages" :key="index">
                <v-img :src="image" height="400" class="mb-4 rounded-lg stylish-image" contain></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <!-- Content Section -->
          <v-col cols="12" md="6">
            <div class="text-body-2 stylish-content">
              <p>
                Michotech Engineering is a forward-thinking company specializing in advanced waste management solutions and industrial baking technology. With a focus on designing and manufacturing cutting-edge waste incinerators and energy-efficient backing ovens, we aim to meet the growing needs for sustainable waste disposal and food production systems.
              </p>
              <p>
                Our incinerators are designed to handle various types of waste, ensuring environmentally friendly disposal with minimal emissions. Meanwhile, our state-of-the-art baking ovens are built to provide consistent, high-quality baking results with improved energy savings. At Michotech Engineering, we are committed to innovation, sustainability, and providing exceptional service to our clients.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Mission and Vision Cards -->
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <v-card class="elevation-4 rounded-xl stylish-mission-vision">
          <v-card-title class="justify-center stylish-header">
            <h3 class="text-h6 font-weight-bold stylish-subtitle">Our Mission</h3>
          </v-card-title>
          <v-card-text>
            <div class="text-body-2 stylish-content">
              <p>
                Our mission is to revolutionize waste management and baking industries by developing sustainable, innovative, and energy-efficient solutions. We strive to lead the market in waste incineration and industrial baking technologies, ensuring that our clients can operate with minimal environmental impact while maximizing productivity and quality.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="elevation-4 rounded-xl stylish-mission-vision">
          <v-card-title class="justify-center stylish-header">
            <h3 class="text-h6 font-weight-bold stylish-subtitle">Our Vision</h3>
          </v-card-title>
          <v-card-text>
            <div class="text-body-2 stylish-content">
              <p>
                Our vision is to become a global leader in providing environmentally responsible waste disposal systems and high-performance industrial baking solutions. We envision a world where our technologies contribute to cleaner environments and more efficient food production systems, fostering sustainability and innovation in every aspect of our work.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      aboutImages: [
        require('@/assets/m/m1.jpg'), // Replace with actual image links
        require('@/assets/m/m2.jpg'),
        require('@/assets/m/m3.jpg'), // Replace with actual image links
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Navigate back
    },
  },
};
</script>

<style scoped>
/* Card Styles */
.stylish-card {
  background-color: #fefefe;
  border: 1px solid #e0e0e0;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.stylish-card:hover {
  transform: scale(1.03);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

.stylish-card-text {
  padding: 20px;
}

.stylish-header {
  background-color: #f0f4f8;
  border-bottom: 2px solid #3498db;
}

.stylish-title {
  color: #3498db;
  letter-spacing: 1px;
}

/* Carousel */
.stylish-carousel {
  border-radius: 12px;
  overflow: hidden;
}

.stylish-image {
  border: 4px solid #3498db;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.stylish-image:hover {
  transform: scale(1.05);
}

/* Mission and Vision Cards */
.stylish-mission-vision {
  background-color: #fafafa;
  transition: all 0.3s ease-in-out;
}

.stylish-mission-vision:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.stylish-subtitle {
  color: #2c3e50;
  border-bottom: 2px solid #8e44ad;
  text-transform: uppercase;
}

/* Text Styles */
.stylish-content {
  color: #616161;
  line-height: 1.8;
  font-size: 1.1rem;
}

/* Alerts */
.stylish-alert {
  background-color: #ffe6e6;
  border: 1px solid #ff6666;
  color: #d32f2f;
}

/* Progress Bar */
.stylish-progress {
  border-radius: 10px;
  height: 6px;
}
</style>
