<template>
  <v-container>
    <!-- Carousel for Hero Images -->
      <!-- Carousel Section -->
      <v-carousel
        hide-delimiter-background
        show-arrows
        cycle
        :interval="7000"
        height="500px"
        class="stylish-carousel"
      >
        <!-- Loop through carousel items -->
        <v-carousel-item
          v-for="(item, index) in carouselItems"
          :key="index"
          class="carousel-item"
        >
          <!-- Background Image -->
          <v-img
          :src="item.image"
          class="carousel-bg"
          max-height="650px"  
          min-height="200px"  
          width="100%"
          cover
        ></v-img>

          <!-- Overlay Text Content -->
          <div class="carousel-text-overlay">
            <v-col
              class="text-center d-flex flex-column align-center justify-center"
            >
              <!-- Title with fade-in animation -->
              <transition name="fade-slide" mode="out-in">
                <h1
                  v-if="item.title"
                  class="display-2 white--text font-weight-bold animated fadeInDown text-shadow"
                  :key="`title-${index}`"
                >
                  {{ item.title }}
                </h1>
              </transition>

              <!-- Subtitle with fade-in effect after title -->
              <transition name="fade-slide" mode="out-in">
                <h3
                  v-if="item.subtitle"
                  class="white--text mb-4 text-shadow animated fadeIn delay-1s"
                  :key="`subtitle-${index}`"
                >
                  {{ item.subtitle }}
                </h3>
              </transition>

              <!-- Button with hover effect and animation -->
              <v-btn
                color="success"
                class="elevated-btn animated fadeInUp delay-2s stylish-button"
                @click="goToAction(item.action)"
              >
                {{ item.buttonText }}
              </v-btn>
            </v-col>
          </div>
        </v-carousel-item>
      </v-carousel>

    <!-- Welcome Message -->
    <v-row align="center" justify="center" class="mt-5">
      <v-col cols="12" md="12">
            <h3 class="heading">{{ welcomeMessage }}</h3>
      </v-col>
    </v-row>

    <!-- Featured Products (Waste Management Technology and Baking Ovens) -->
    <v-row class="mt-5">
      <v-col cols="12">
        <h2 class="heading">Our Waste Incinerators</h2>
        <v-row justify="center">
          <v-col v-for="(product, index) in featuredProducts" :key="index" cols="12" sm="6" md="4">
            <v-card class="hoverable stylish-card">
              <v-img
                :src="product.image"
                class="carousel-bg stylish-image"
                height="250px"
                cover
              >
                <!-- Gradient overlay -->
                <div class="image-overlay"></div>
              </v-img>
              
              <v-h3 class="product-title">{{ product.name }}</v-h3>
              <h5 class="product-description">{{ product.description }}</h5>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Featured Services (Waste Management Solutions and Oven Maintenance) -->
    <v-row class="mt-5">
      <v-col cols="12">
        <h2 class="heading">Our Baking Ovens</h2>
        <v-row justify="center">
          <v-col v-for="(service, index) in featuredServices" :key="index" cols="12" sm="6" md="4">
            <v-card class="hoverable stylish-card" >
              <v-img
                :src="service.image"
                class="carousel-bg stylish-image"
                height="250px"
                cover
              >
                <!-- Gradient overlay -->
                <div class="image-overlay"></div>
              </v-img>
              <h3 class="service-title">{{ service.name }}</h3>
              <h5 class="service-description">{{ service.description }}</h5>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Testimonials Section -->
    <v-container class="mt-10 stylish-testimonials">
      <h3 class="text-center stylish-heading mb-8">What Our Clients Say</h3>
      
      <v-row justify="center">
        <v-col cols="12" md="12" sm="8">
          <v-carousel hide-delimiters interval="7000" cycle>
            <v-carousel-item v-for="(testimonial, index) in testimonials" :key="index">
              <v-card class="testimonial-card text-center py-6">
                <v-avatar size="120" class="mx-auto mb-4 stylish-avatar">
                  <v-img :src="testimonial.image"></v-img>
                </v-avatar>
                <v-card-title class="testimonial-name mb-2">{{ testimonial.name }}</v-card-title>
                <v-card-subtitle class="testimonial-message mb-4">{{ testimonial.message }}</v-card-subtitle>
                <v-divider></v-divider>
                <v-card-actions class="mt-4">
                  <v-icon large class="testimonial-quote-icon">mdi-format-quote</v-icon>
                </v-card-actions>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
  <v-row justify="center">
    <v-col cols="12" md="10" sm="8">
      <h2 class="text-center">Our Location</h2>
      <p class="text-center">Find us at our main office located at the heart of the city.</p>

      <!-- Embedded Google Map -->
      <div class="map-embed">
        <iframe
          width="100%"
          height="400"
          frameborder="0"
          style="border:0"
          allowfullscreen
          aria-hidden="false"
          tabindex="0"
          :src="mapSrc"
        ></iframe>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HomeM',
  data() {
    return {
      homeList: [],
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.1160996746!2d-74.24559537703412!3d40.69767006872521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDAlMzcJTiA3NMKwMDInRTQ!5e0!3m2!1sen!2sus!4v1609385894395!5m2!1sen!2sus",
      welcomeMessage: "Welcome to MICOTECH ENGINEERING",
      carouselItems: [
        {
          title: "Innovative Waste Management Technology",
          subtitle: "Leading the future in sustainable waste solutions.",
          buttonText: "Learn More",
          action: 'solutions',
          image: require("@/assets/m/m7.jpg")
        },
        {
          title: "Advanced Baking Ovens",
          subtitle: "State-of-the-art baking technology for every need.",
          buttonText: "View Ovens",
          action: 'ovens',
          image: require("@/assets/m/m8.jpg")
        },
        {
          title: "Custom Waste Solutions",
          subtitle: "Tailored solutions for businesses and communities.",
          buttonText: "Get a Quote",
          action: 'quote',
          image: require("@/assets/m/m9.jpg")
        }
      ],
      featuredProducts: [
        { id: 1, name: 'MICO 00204', description: '• Ideal for smaller facilities • Capacity: 200kg per load.', image: require('@/assets/m/m4.jpg') },
        { id: 2, name: 'MICO 00207', description: '    • Suitable for medium to large-scale waste management • Handles larger volumes with enhanced efficiency.', image: require('@/assets/m/m5.jpg') },
      ],
      featuredServices: [
        { id: 1, name: 'Model MICO 00204/2008', description: '• Power Consumption: 2kW at 220V • Baking Capacity: 432 bread in 10-12 minutes • Baking Area: 4.2 square meters (1.4m x 3m) • Energy Sources: Wood, fuel, biogas, and oil • Batteries: 12 • Fuel Consumption: 1.7 liters per hour • Dimensions: 200mm (H) x 180mm (D) x 260mm (W).', image: require('@/assets/m/m1.jpg') },
        { id: 2, name: 'Model MICO 00205/2009', description: '• Power Consumption: 2.2kW at 220V or 380V • Baking Capacity: 720 bread in 10-13 minutes • Baking Area: 6.48 square meters (2.16m x 3m) • Energy Sources: Wood, fuel, biogas, and oil • Batteries: 18 • Fuel Consumption: 2 liters per hour • Dimensions: 220mm (H) x 180mm (D) x 300mm (W).', image: require('@/assets/m/m2.jpg') },
        { id: 3, name: 'Model MICO 00207/2013', description: '• Power Consumption: 3kW at 220V or 380V • Baking Capacity: 864 bread in 10-13 minutes • Baking Area: 8.64 square meters (2.16m x 4m) • Energy Sources: Wood, fuel, biogas, and oil • Batteries: 24 • Fuel Consumption: 2.5 liters per hour • Dimensions: 220mm (H) x 180mm (D) x 300mm (W).', image: require('@/assets/m/m3.jpg') },
        {id: 4, name: 'Model MICO 00206/2010', description: '• Power Consumption: 3kW at 220V or 380V • Baking Capacity: 960 bread in 10-13 minutes • Baking Area: 9.2 square meters (2.3m x 4m) • Energy Sources: Wood, fuel, biogas, and oil • Batteries: 24 • Fuel Consumption: 3 liters per hour • Dimensions: 220mm (H) x 180mm (D) x 300mm (W)', image: require('@/assets/m/m4.jpg') }
      ],
      testimonials: [
        { id: 1, name: 'Sarah Miller', message: 'Their waste management solutions are top-notch!' , image: require('@/assets/m1.jpg')},
        { id: 2, name: 'David Johnson', message: 'Highly recommend their baking ovens for industrial use.' ,image: require('@/assets/m1.jpg')},
        { id: 3, name: 'Emily Davis', message: 'Fantastic service and great results!',image: require('@/assets/m1.jpg') },
      ]
    };
  },
  methods: {
    goToAction(action) {
      this.$router.push({ name: action });
    }
  }
};
</script>

<style scoped>
.stylish-carousel {
  border-radius: 8px;
  overflow: hidden;
}

.carousel-bg {
  opacity: 0.85;
}

.carousel-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.stylish-button {
  transition: all 0.3s ease;
}

.stylish-button:hover {
  transform: translateY(-3px);
}

.welcome-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.welcome-card {
  box-shadow: none;
}

.stylish-heading {
  font-weight: bold;
}

.stylish-card {
  position: relative;
  overflow: hidden;
}

.stylish-image {
  transition: all 0.3s ease;
}

.stylish-card:hover .stylish-image {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
}

.stylish-testimonials {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.testimonial-card {
  background-color: #ffffff;
}

.stylish-avatar {
  transition: all 0.3s ease;
}

.testimonial-quote-icon {
  color: #5c6bc0;
}

.map-embed {
  margin-top: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.heading {
  font-size: 2.5rem; /* Adjust the font size as needed */
  font-weight: 700; /* Make the font bold */
  text-align: center; /* Center the heading */
  color: #3f51b5; /* Set a stylish color */
  margin: 1.5rem 0; /* Add some vertical margin */
  position: relative; /* Required for the pseudo-elements */
  text-transform: uppercase; /* Transform text to uppercase */
  letter-spacing: 1px; /* Add spacing between letters */
}

.heading::after {
  content: ''; /* Creates a pseudo-element */
  display: block; /* Make it a block element */
  width: 60%; /* Width of the underline */
  height: 4px; /* Thickness of the underline */
  background-color: #094033; /* Color of the underline */
  margin: 0.5rem auto; /* Center it below the heading */
  border-radius: 2px; /* Rounded edges */
  transition: width 0.3s ease; /* Animation for hover effect */
}

.heading:hover::after {
  width: 100%; /* Change width on hover */
}

.heading:hover {
  color: #094033; /* Change text color on hover */
  transform: scale(1.05); /* Slightly enlarge the heading */
  transition: color 0.3s, transform 0.3s; /* Animation for color and scaling */
}
</style>
