<template>
  <v-app color="grey-lighten-5" style="background-color: #F7F8FB;">
    <Navbar />

    <v-main style="flex: 1;" class="mb-10">
      <router-view />
    </v-main>

    <!-- Footer -->
   <!-- Inversed Curve Footer -->
   <v-footer class="footer custom-footer">
      <div class="footer-curve"></div>
      <v-container>
        <v-row justify="center" class="footer-section">
          <!-- Company Info -->
          <v-col cols="12" sm="4" class="footer-column text-center">
            <h4 class="footer-heading">Micotech Engineering</h4>
            <p class="footer-text">
              Providing high-quality waste management solutions and industrial baking technologies to our clients.
                        </p>
          </v-col>

          <!-- Quick Links -->
          <v-col cols="12" sm="4" class="footer-column text-center">
            <h4 class="footer-heading">Quick Links</h4>
            <ul class="footer-links">
              <li><a href="/">Home</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/products">Products</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </v-col>

          <!-- Contact Info -->
          <v-col cols="12" sm="4" class="footer-column text-center">
            <h4 class="footer-heading">Contact Us</h4>
            <p class="footer-text">Phone: (+251) 456-7890</p>
            <p class="footer-text">Email: info@micotech.com</p>
            <div class="social-icons">
              <v-icon large class="social-icon">mdi-facebook</v-icon>
              <v-icon large class="social-icon">mdi-twitter</v-icon>
              <v-icon large class="social-icon">mdi-linkedin</v-icon>
            </div>
          </v-col>
        </v-row>

        <!-- Footer Bottom -->
        <v-row justify="center">
          <v-col class="text-center mt-8">
            <div class="footer-bottom">
              &copy; {{ new Date().getFullYear() }} — <strong>Micotech Engineering</strong>
              <br />
              <span>All Rights Reserved.</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>
<script>
import Navbar from './components/AppNavbar.vue';
export default {
components: {
  Navbar
},
  data() {
    return {
     // drawer: false,
    };
  },
}
</script>
<style scoped>
/* General Footer Styles */
.custom-footer {
  background-color: #094033; /* Deep background color */
  color: #fff;
  padding: 60px 0;
  border-top: 4px solid #3498db;
  position: relative;
  overflow: hidden;
}

.footer-section {
  margin-bottom: 40px;
}

.footer-column {
  padding: 20px;
}

.footer-heading {
  font-family: 'Playfair Display', serif;
  font-size: 1.75rem;
  color: #fff;
  margin-bottom: 16px;
}

.footer-text {
  font-size: 1rem;
  color: #bdc3c7;
  margin-bottom: 8px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #3498db;
}

.social-icons {
  margin-top: 20px;
}

.social-icon {
  color: #fff;
  margin: 0 10px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  color: #3498db;
}

.footer-bottom {
  font-size: 0.875rem;
  color: #bdc3c7;
}

/* Inversed Curve Styling */
.footer-curve {
  position: absolute;
  top: -80px;  /* Adjust to control the depth of the curve */
  left: 0;
  right: 0;
  height: 150px;  /* Height of the curve */
  background: #094033;
  z-index: 1;
  border-radius: 100% / 50px; /* This creates the inverse curve */
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
  transform: scaleY(-1); /* Invert the curve */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .footer-heading {
    font-size: 1.5rem;
  }
  .footer-text {
    font-size: 0.9rem;
  }
  .footer-curve {
    height: 120px;
    top: -60px;
    border-radius: 100% / 40px;
  }
}


</style>