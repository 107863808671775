<template>
  <v-container class="my-5">
    <v-row>
      <v-col cols="12">
        <h1 class="text-center font-weight-bold title">What Our Clients Say</h1>
        <v-divider class="my-4"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(testimonial, index) in testimonials"
        :key="index"
        cols="12"
        md="4"
        class="d-flex justify-center"
      >
        <v-card class="testimonial-card">
          <v-img
            :src="testimonial.backgroundImage"
            height="200px"
            class="card-background"
          >
            <v-card-title class="card-title">
              <v-avatar size="70" class="mr-2">
                <img :src="testimonial.avatar" alt="User Avatar" />
              </v-avatar>
              <div class="title-container">
                <h4 class="mb-0 name" >{{ testimonial.name }}</h4>
                <span class="font-weight-light position">{{ testimonial.position }}</span>
              </div>
            </v-card-title>
            <v-card-overlay></v-card-overlay>
          </v-img>
          <v-card-text>
            <p class="testimonial-message">{{ testimonial.message }}</p>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="white" class="read-more-btn" @click="readMore(testimonial.link)">Read More</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          name: 'Aisha Tesfaye',
          position: 'Hospital Manager',
          message: 'MicoTech’s incinerators have significantly improved our waste management process. The efficiency is unmatched!',
          avatar: require('@/assets/m/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Hospital', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Kedir Abebe',
          position: 'Environmental Specialist',
          message: 'The air pollution control features of MicoTech systems give us peace of mind. We are doing our part for the environment.',
          avatar: require('@/assets/m/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Environment', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Selam Tesfamariam',
          position: 'Chief Engineer',
          message: 'The durability of MicoTech’s products is commendable. They have become our trusted partner in waste management.',
          avatar: require('@/assets/m/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Engineering', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Hanna Alemayehu',
          position: 'Baker',
          message: 'The baking ovens are fantastic! We can produce a large quantity of bread in no time.',
          avatar: require('@/assets/m/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Baking', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Mekonnen Gebrehiwet',
          position: 'Restaurant Owner',
          message: 'Our bakery’s efficiency has increased tremendously since we started using MicoTech ovens.',
          avatar: require('@/assets/m/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Restaurant', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Abeba Kidane',
          position: 'Catering Manager',
          message: 'Highly recommend MicoTech! Their ovens and incinerators are both innovative and reliable.',
          avatar: require('@/assets/m/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Catering', // Replace with actual image URLs
          link: '#'
        }
      ]
    }
  },
  methods: {
    readMore(link) {
      // Logic to handle reading more testimonials or navigating to a detailed page
      console.log('Read more about:', link);
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 2.5rem;
  color: #3e50b4; /* Example color, adjust as needed */
}

.testimonial-card {
  max-width: 400px;
  margin: 10px;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.card-background {
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-title {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center; /* Center-align avatar and text */
}

.title-container {
  margin-left: 10px; /* Space between avatar and text */
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  z-index: 1;
}

.name {
  font-size: 1.2rem;
  color: #076ae3;
}

.position {
  color: #250efb;
}

.testimonial-message {
  font-style: italic;
  color: #555;
  padding: 15px;
}

.read-more-btn {
  background-color: #3e50b4; /* Button color */
  color: white;
  transition: background-color 0.3s;
}

.read-more-btn:hover {
  background-color: #5c6bc0; /* Lighter button color on hover */
}
</style>
